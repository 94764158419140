import { PGridLR_Base } from './pgridLR_Base.js';
import { FillInRenderOptions } from './pgridLR_Common.js';

// import "core-js/stable";
// import "regenerator-runtime/runtime";

import PGridLR_OpenTable_Schema from './PGridLR_OpenTable-schema.json';
import { clone as lodash_clone, cloneDeep as lodash_cloneDeep, mergeWith as lodash_mergeWith, isEqual as lodash_isEqual, isNumber as lodash_isNumber, filter as lodash_filter, find as lodash_find, get as lodash_get, has as lodash_has, merge as lodash_merge, set as lodash_set, union as lodash_union, unionBy as lodash_unionBy, uniq as lodash_uniq, uniqBy as lodash_uniqBy } from 'lodash';
import { expandIfNeeded, indexOfDSProp, mergeCells, getHotValue } from './pgridCell.js';

import PGridUtils from './pgridUtils.js';
import PGridCell from './pgridCell.js';
import PGridMatrices from './pgridMatrices.js'
import { ECellMerge } from './pgridCell.js'

import { conv_sql2pgrid_type } from './pgridConvert.js'



export class PGridLR_OpenTable extends PGridLR_Base {

    //Inherited from base

    //this.type
    //this.name
    //this.x
    //this.y
    //this.linkedRange
    //this.xLength
    //this.yLength

    //Declared in this class
    //this.adjacentCells: {selfCell, aboveCell, leftCell}


    constructor(x, y, pcell, pgridTableStatic) {
        super(x, y, pcell, pgridTableStatic);

        // lr.yLength = pgridDataDynCont[lr.y][lr.x].Meta.yLRLength;
        this.yLength = lodash_get(pcell, "Meta.yLRLength", null);
        this.xLength = lodash_get(pcell, "Meta.xLRLength", null);
        this.yLengthHeaders = 1;
        this.xLengthHeaders = 0;
        this.yLengthHeadersAdjust = 0;
        this.xLengthHeadersAdjust = 0;

        this.ySafeOffsetDistance = lodash_get(pcell, "Meta.Load.SafeOffsetDistanceY", null);
        this.xSafeOffsetDistance = lodash_get(pcell, "Meta.Load.SafeOffsetDistanceX", null);

        this.NoHeader = false;
        this.SkippHeaderStyling = false;
        this.NoRowsInsert = false;
        this.ColFilterEnabled = false;
        this.OutputMode = 'normal'; // 'mutatestore'

        this.schema = PGridLR_OpenTable_Schema;
        if (x == null && y == null && pcell == null && pgridTableStatic == null) {
            //Allow to only set schema
            return;
        }

        this.AxisDefMap = {
            COLUMNS: { type: 'COLUMNS', mapsTo: 'LoadSave', IsTableAxis: true },  //'Column' is a property in the linked range definition
            // ROWS: { type: 'ROWS', mapsTo: 'Rows', IsTableAxis: true },
            // LOOKUPS: { type: 'LOOKUPS', mapsTo: 'Lookups' },
            // FACTS: { type: 'FACTS', mapsTo: 'Facts' }
        }

        // this.Templates = lodash_get(this.linkedRange, "Templates", null);

        this.Overlay = lodash_get(this.linkedRange, "Overlay", null);
        this.OverlayOT = lodash_get(this.linkedRange, "OverlayOT", null);

        this.RemoveUpperRightCakebite = true;

        // this.SkippHeaderStyling = lodash_get(this.linkedRange, "SkippHeaderStyling", false);

        if (lodash_has(this.linkedRange, "Options")) {
            this.NoHeader = lodash_get(this.linkedRange, "Options.NoHeader", false);
            this.SkippHeaderStyling = lodash_get(this.linkedRange, "Options.SkippHeaderStyling", false);
            this.OutputMode = lodash_get(this.linkedRange, "Options.OutputMode", 'normal');
            this.ColFilterEnabled = lodash_get(this.linkedRange, "Options.Columns.Default.ColFilterEnabled", false);
            this.NoRowsInsert = lodash_get(this.linkedRange, "Options.Rows.NoRowsInsert", false);
        }

        if (this.OutputMode == 'mutatestore') {
            this.lrIsHidden = true
        }

        if (this.NoHeader) {
            this.yLengthHeaders = 0;
        }
    }

    whoAmI() {
        return 'I am a PGridLR_OpenTable'
    }

    Phase1_Get_DataSetDefs({ me = null, dataSetDefinitions = null }) {

        let ret = Array();

        let addOpenTableDataSet = function (dtName, overrides) {

            const dsType = "OpenTable_LoadSave" //From LR

            let ff_DSDefs = dataSetDefinitions.filter(dsd => {
                return dsd.Type === dsType && dsd.Name == dtName
            });

            if (ff_DSDefs.length != 1) {
                throw new Error(`Could not find one  dataset of name '${dtName}' of type '${dsType}'`);
            }

            let ffDSDef = ff_DSDefs[0];


            // console.warn(`Phase1_Get_DataSetDefs() FUB: dont add any datasets for now`);
            ret.push({ DSName: dtName, DSDef: ffDSDef, DSOverrides: overrides, LRName: me.name, AltArgs: null });
        }

        try {

            window.PGridClientDebugMode >= 3 && console.warn(`Phase1_Get_DataSetDefs() FUB: dont add any datasets for now`);

            let cols = me.linkedRange[me.AxisDefMap.COLUMNS.mapsTo];
            // let rows = me.linkedRange[me.AxisDefMap.ROWS.mapsTo];
            // let loadSave = me.linkedRange["LoadSave"];
            // let rows = me.linkedRange["Rows"];
            // let lookups = lodash_get(me, "linkedRange.Lookups", null);

            let axisObjs = [cols];

            axisObjs.forEach(axisDef => {
                // if ("PivotHierarchy" in axisDef) {
                if ("OpenTableDataSet" in axisDef) {

                    let dsName = axisDef.OpenTableDataSet;
                    // },
                    // "Rows": {
                    //     "OpenTableDataSet": "Hierarki_GetPlanningAccountHierarchy",
                    //     "Override": {
                    //         "GridKey": "CC748C64-0424-4617-93A6-338451B144C1"    <--------- Like this
                    //     },
                    //     "TotalLengthAdjust": 0
                    // },
                    // "OverlayCSSClass": "pg-hide-this-lr",
                    // let overrides = lodash_get(axisDef, "Override", null);

                    addOpenTableDataSet(dsName, null);
                } else {
                    throw new Error(`Missing "OpenTableDataSet" in LR ${me.name}`);
                }
            });



        } catch (err) {
            throw new Error(`PGridLR_OpenTable:Phase1_Get_DataSetDefs() ${me.name}: Could not get dataset with name: ${err.message || err}`);
        }

        return ret;
    }


    //contect: client (resolver_common.js)
    //inputs: pgridDim
    //sets: this.axisData ()

    /* example:
    this.axisData: {
        "COLUMNS": [
            {
                "Name": "Hierarki_GetPlanningColumnHierarchy",
                "Header": {
                    "Id": {
                        "SrcCol": "ColId",
                        "ColIdx": 0
                    },
                    "Value": {
                        "SrcCol": "ColId",
                        "ColIdx": 1
                    },
                    "Name": {
                        "SrcCol": "ColName",
                        "ColIdx": 2
                    },
                    "Description": {
                        "SrcCol": "ColDescr",
                        "ColIdx": 3
                    },
                    "HierarchyLevel": {
                        "SrcCol": "HierarchyLevel",
                        "ColIdx": 4
                    },
                    "HierarchyId": {
                        "SrcCol": "HierarchyId",
                        "ColIdx": 5
                    },
                    "Writable": {
                        "SrcCol": "mimPg_IsWritable",
                        "ColIdx": 6
                    },
                    "Type": {
                        "SrcCol": "ColType",
                        "ColIdx": 7
                    }
                },
                "DataSet": [
                    [
                        "kkg1-3",
                        "kkg1-3",
                        "Investeringskalkyl / Projektansökan",
                        "Kalkyl angiven i projektanmälan inkl utökningar.",
                        1,
                        "KK-cols1",
                        0,
                        "MEASURE_ON_PROJECT_PERIOD"
                    ],
                    [
                        "kkg1-4",
                        "kkg1-4",
                        "Upparbetat",
                        "Upparbetad kostnad (Utfall).",
                        1,
                        "KK-cols1",
                        0,
                        "MEASURE_ON_PROJECT_PERIOD"
                    ],
                    [
                        "kkg1-5",
                        "kkg1-5",
                        "Aktuell Slutkostnadsprognos",
                        "Sammanställd prognos för aktuellt konto.",
                        1,
                        "KK-cols1",
                        1,
    .
    .
    .
    "ROWS": [
        {
            "Name": "Hierarki_GetPlanningAccountHierarchy",
            "Header": {
                "Id": {
                    "SrcCol": "AccountId",
                    "ColIdx": 0
                },
                "Value": {
                    "SrcCol": "AccountId",
                    "ColIdx": 1
    .
    .
    .
                    1,
                        1
                    ],
                    [
                        "2291",
                        "2291",
                        "2291 Bonus",
                        "2291 Bonus",
                        "prod19",
                        4,
                        "ProjKonto1",
                        1,
                        1
                    ],
                    [
                        "2293",
                        "2293",
                        "2293 Valfritt projektspecifikt",
                        "2293 Valfritt projektspecifikt",
                        "prod19",
                        4,
                        "ProjKonto1",
                        1,
                        1
                    ]
                    ],
                    "Parent": {
                    "SrcCol": "ParentAccountId"
                    },
                    "LookupIdx_ParentToMe": {
                    "0": [
                        0,
                        1,
                        2,
                        3,
                        4
                    ],
                    "1": [
                        5,
                        6,
                        7
                    ],
                    "2": [
                        8,
                        9,
                        10,
                        11,
                        12,
                        13
                    ],
                    "3": [
                        14,
                        15,
                        16,
    */


    Phase2_Load_DimDataInAxisData(pgridDim) {
        try {
            window.PGridClientDebugMode >= 3 && console.warn(`Phase2_Load_DimDataInAxisData() FUB: dont add any datasets for now`);
            if (true) {

                const axisToLoad = Object.keys(this.AxisDefMap).filter(x => this.AxisDefMap[x].IsTableAxis).map((x) => { return { key: x, value: this.AxisDefMap[x] } });

                for (let i = 0; i < axisToLoad.length; i++) { //LOADSAVE
                    let atl = axisToLoad[i];

                    window.PGridClientDebugMode >= 3 && console.debug(`Phase2_Load_DimDataInAxisData() Processing ${atl.key}`)

                    let axisDef = this.linkedRange[atl.value.mapsTo];


                    if (lodash_get(axisDef, "OpenTableDataSet", null) != null || lodash_get(axisDef, "DataSetName", null) != null) {



                        let dsName = axisDef.OpenTableDataSet || axisDef.DataSetName;
                        if (dsName === null) {
                            throw new Error("No OpenTableDataSet name fund");
                        }

                        //FUB: pgridDimDS is Vue reactive, which slows down performance
                        let pgridDimDS = lodash_get(pgridDim, dsName, null);

                        if (pgridDimDS != null) {

                            let axisDataName = dsName + "_Columns";
                            let columnDefs = [];


                            let pgridDimDS_length = pgridDimDS.length;
                            for (let c = 0; c < pgridDimDS_length; c++) {
                                columnDefs.push(pgridDimDS[c]);
                            }


                            if (!(atl.key in this.axisData)) {
                                this.axisData[atl.key] = [];
                            }

                            this.axisData[atl.key].push({ Name: axisDataName, ColumnDefinitions: columnDefs });
                        } else {
                            throw new Error(`Phase2_Load_DimDataInAxisData() DataSet not in result: ${dsName}`);
                        }

                    }
                    else {
                        throw new Error(`No dimensions specified for ${axisDef}`)
                    }
                }
            }
            //Dont return anything (have insted populated axisData)
            return null;

        } catch (err) {
            let errMsg = `Phase2_Load_DimDataInAxisData (${this.name}) got exception: ${err.message || err}`;
            throw new Error(errMsg)
        }
    }


    // Phase3_Insert_DynDimData(pgridTableDynCopy, pgridAxis, addedRowsOffsetLR, state) {
    Phase3_Insert_DynDimData = async function (pgridTableDyn, pgridAxis, addedRowsOffsetLR, state) {

        this.y = PGridMatrices.Get_LRsFromTable(pgridTableDyn, { onlyThisLR: this.name, source: `pgridLR_OpenTable.js Phase3_Insert_DynDimData() name: ${this.name}` }).y;

        let pgridTableDynCopy = lodash_clone(pgridTableDyn); //PERF?

        let ret = { pgridTableDyn: null, addedRowsOffset: null, lowerRightCoord: { y: null, x: null }, lrIsHidden: null }


        ret.lrIsHidden = this.lrIsHidden;

        ret.lowerRightCoord.y = this.y + this.yLength;
        ret.lowerRightCoord.x = this.x + this.xLength;

        let hasAddedFooter = false;
        let hasAddedExtraHeader = false;

        /*
        this.yLength = 0;
        this.xLength = 0;
        ret.lowerRightCoord.y = this.y + this.yLength;
        ret.lowerRightCoord.x = this.x + this.xLength;
        let accAddedRowsOffset = 0;

        ret.pgridTableDyn = pgridTableDynCopy;
        ret.addedRowsOffset = accAddedRowsOffset
        */

        try {

            window.PGridClientDebugMode >= 3 && console.warn(`Phase3_Insert_DynDimData() FUB: dont add any datasets for now`);


            let ColumnDefinitions_length = this.axisData.COLUMNS[0].ColumnDefinitions.length;


            for (let c = 0; c < ColumnDefinitions_length; c++) {
                let colDef_JSON_Clone = JSON.parse(JSON.stringify(this.axisData.COLUMNS[0].ColumnDefinitions[c]));


                if (pgridTableDyn[0].length < ret.lowerRightCoord.x) {
                    //Add required columns
                    PGridMatrices.AddEmptyCol(pgridTableDyn, (ret.lowerRightCoord.x - pgridTableDyn[0].length));
                }

                if (hasAddedFooter || ("Footer" in colDef_JSON_Clone)) {
                    hasAddedFooter = true;
                }

                if (hasAddedExtraHeader || ("ExtraHeader" in colDef_JSON_Clone)) {
                    hasAddedExtraHeader = true;
                }

                let newHeaderCell = {
                    ValStr: 'colDisplayName' in colDef_JSON_Clone["Header"].Meta.OT ? colDef_JSON_Clone["Header"].Meta.OT.colDisplayName : colDef_JSON_Clone["Header"].Meta.OT.colName,
                    Meta: {
                        "Save": {
                            "Writable": false
                        }
                    },
                    "Format": "pg-is-lr" /* white borders otherwise */
                }


                {
                    let headerRowFormatCell = pgridTableDyn[ret.lowerRightCoord.y - 1][ret.lowerRightCoord.x];
                    if (headerRowFormatCell != null && "Meta" in headerRowFormatCell && "Style" in headerRowFormatCell.Meta) {

                        if (newHeaderCell == null) {
                            newHeaderCell = {};
                        }
                        if (!("Meta" in newHeaderCell)) {
                            newHeaderCell.Meta = {};
                        }
                        newHeaderCell.Meta.Style = headerRowFormatCell.Style;
                    }
                }



                if (newHeaderCell == null) {
                    newHeaderCell = {};
                }
                if (!("Meta" in newHeaderCell)) {
                    newHeaderCell.Meta = {};
                }

                if (!("Validator" in newHeaderCell.Meta)) {
                    newHeaderCell.Meta.Validator = {};
                }

                if (this.ColFilterEnabled == true) {
                    newHeaderCell.Meta.Validator.Type = 'colfilter';
                }


                //Lastly, clone Header definition from SQL
                newHeaderCell = lodash_merge(newHeaderCell, colDef_JSON_Clone["Header"]);

                if (this.lrIsHidden) {
                    newHeaderCell.Format = "pcell-hidden"
                }

                if (!this.NoHeader) {
                    pgridTableDynCopy[ret.lowerRightCoord.y - 1][ret.lowerRightCoord.x] = ECellMerge(pgridTableDynCopy[ret.lowerRightCoord.y - 1][ret.lowerRightCoord.x], newHeaderCell);
                }

                this.xLength = this.xLength + 1;


                ret.lowerRightCoord.x = this.x + this.xLength;
                this.xLengthHeaders = this.xLength;

            }

            if (hasAddedFooter) {
                console.debug(`************************ hasAddedFooter = true ***********************************`);

                for (let c = 0; c < ColumnDefinitions_length; c++) {
                    let colDef_JSON_Clone = JSON.parse(JSON.stringify(this.axisData.COLUMNS[0].ColumnDefinitions[c]));

                    if ("Footer" in colDef_JSON_Clone) {

                        let newFooterCell = {
                            Meta: {
                                "Save": {
                                    "Writable": false
                                }
                            },
                            "Format": "pg-is-lr" /* white borders otherwise */
                        }


                        //Lastly, clone Header definition from SQL
                        newFooterCell = lodash_merge(newFooterCell, colDef_JSON_Clone["Footer"]);


                        pgridTableDynCopy[ret.lowerRightCoord.y - 1 + 2][this.x + c] = ECellMerge(pgridTableDynCopy[ret.lowerRightCoord.y - 1 + 2][this.x + c], newFooterCell);


                    }

                }
            }

            if (hasAddedExtraHeader) {
                console.debug(`************************ hasAddedExtraHeader = true ***********************************`);

                for (let c = 0; c < ColumnDefinitions_length; c++) {
                    let colDef_JSON_Clone = JSON.parse(JSON.stringify(this.axisData.COLUMNS[0].ColumnDefinitions[c]));

                    if ("ExtraHeader" in colDef_JSON_Clone) {

                        let newExtraHeaderCell = {
                            Meta: {
                                "Save": {
                                    "Writable": false
                                }
                            },
                            "Format": "pg-is-lr" /* white borders otherwise */
                        }


                        //Lastly, clone Header definition from SQL
                        newExtraHeaderCell = lodash_merge(newExtraHeaderCell, colDef_JSON_Clone["ExtraHeader"]);


                        pgridTableDynCopy[ret.lowerRightCoord.y - 2][this.x + c] = ECellMerge(pgridTableDynCopy[ret.lowerRightCoord.y - 2][this.x + c], newExtraHeaderCell);


                    }

                }
            }

            this.yLength = this.yLength + 1;
            ret.lowerRightCoord.y = this.y + this.yLength;

            ret.pgridTableDyn = pgridTableDynCopy;
            ret.addedRowsOffset = 0


        } catch (Phase3_Insert_DynDimData_error) {
            let errMsg = `> ${this.type}: ${this.type}: Phase3_Insert_DynDimData_error: ${Phase3_Insert_DynDimData_error.message || Phase3_Insert_DynDimData_error}`;
            throw new Error(errMsg);
            this.errors.push(JSON.parse(JSON.stringify(Phase3_Insert_DynDimData_error.toString())));
        }

        return ret;
    }

    Phase4_Insert_Metadata(pgridTable, pgridAxis /* FACTS */, lr) {


        let pgridTableCopy = pgridTable;

        // this.y = PGridMatrices.Get_LRsFromTable(pgridTable, { onlyThisLR: this.name, source: `pgridLR_EPivot.js Phase4_Insert_Metadata()` }).y;

        const insert_cell = {};

        let yLR = this.y;
        let xLR = this.x;

        lodash_set(insert_cell, "Meta.yLRLength", this.yLength);
        lodash_set(insert_cell, "Meta.xLRLength", this.xLength);


        let insert_Y = yLR;
        let insert_X = xLR;

        let merged_cell = mergeCells(pgridTableCopy[insert_Y][insert_X], insert_cell);
        pgridTableCopy[insert_Y][insert_X] = merged_cell;


        return pgridTableCopy;

    }

    Phase4_1_GetSpecializedSQLQuery(lr) {
        let ret = null;
        ret = lr.linkedRange.LoadSave.OpenTableDataSet;
        return ret;
    }

    /* Generate fact range, but don't consider merging here (see Phase7_Insert_FactRange) */
    Phase6_Generate_FactRange(pgridDataDyn, factData, lang = null) {
        let factRangeData = [];



        this.y = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_OpenTable.js Phase6_Generate_FactRange() name: ${this.name}` }).y;

        const pgridDataLRCell = pgridDataDyn[this.y][this.x];

        let dataLen = factData.SpecializedRows.length;
        // let colLen = dataLen ? factData.SpecializedRows[0].length : 0;
        let colLen = factData.SpecializedColDef.length;
        this.yLength = dataLen; //New LR y length 
        this.xLength = colLen; //New LR x length 


        // factRangeData.push([{ ValStr: "A" }, { ValStr: "B" }, { ValStr: "C" }, { ValStr: "D" }]);
        // factRangeData.push([{ Val: 1 }, { Val: 2 }, { Val: 3 }, { Val: 4 }]);
        // factRangeData.push([{ Val: 5 }, { Val: 6 }, { Val: 7 }, { Val: 8 }]);

        // this.yLength = factData.SpecializedRows.length;

        // if (this.yLength > 0) {
        //     this.xLength = factData.SpecializedRows[0].length;
        // }


        for (let newY = 0; newY < this.yLength; newY++) {
            let newRow = [];
            for (let newX = 0; newX < this.xLength; newX++) {
                newRow.push(undefined);
            }
            factRangeData.push(newRow);
        }

        if (dataLen == 0) {

            let newRowEmpty = [];

            for (let c = 0; c < colLen; c++) {

                let newRowEmptyCell = {
                    Meta: {
                        Validator: {
                            Type: 'text'
                        },
                        Save: {
                            Writable: false
                        }
                    },
                    Format: "pg-center"
                };

                newRowEmptyCell.ValStr = "-";

                newRowEmptyCell.Meta.Style = "";

                newRowEmpty.push(newRowEmptyCell);


            }

            factRangeData.push(newRowEmpty);

            this.yLength = 1; //empty data set is visualzed with a row with only '-' cells

        } else {

            let newRowTemplate = [];

            for (let r = 0; r < dataLen; r++) {

                let newRow = [];


                for (let eCol = 0; eCol < colLen; eCol++) {

                    let newRowCell = null;
                    // retSpecializedColDef.push({ colName: colName, colSystemSqlType: colSystemSqlType, altName: altName, colOverride: colOverride } /*colSystemSqlTypeOverride, colPGTypeOverride, colPGSimplyfiedProps]*/);

                    let specColDef = factData.SpecializedColDef[eCol];

                    let sqlType = specColDef["Header"].Meta.OT.colSystemSqlType;
                    let sqlPGSimplyfiedProps_OVERRIDE = factData.SpecializedColDef[eCol]["Header"].Meta.OT.colPGSimplyfiedProps;

                    let validType = conv_sql2pgrid_type(sqlType);

                    let inVal = factData.SpecializedRows[r][eCol];

                    if (!this.lrIsHidden) {
                        newRowCell = {
                            Meta: {
                                Validator: {
                                    Type: validType
                                },
                                Save: {
                                    Writable: true
                                }
                            }
                        };
                    } else {
                        newRowCell = {};
                    }


                    //Apply (if any) Rows template
                    if ("Rows" in specColDef) {
                        newRowCell = lodash_merge(newRowCell, specColDef.Rows);
                    }

                    FillInRenderOptions(newRowCell, validType, lang);

                    if (validType === "integer") {
                        newRowCell.Val = inVal;
                    }
                    else if (validType.indexOf("decimal") == 0) {
                        newRowCell.Val = inVal;
                    }
                    else if (validType == "checkbox") {
                        newRowCell.ValStr = inVal;
                    }
                    else if (validType == "yesno") {
                        newRowCell.ValStr = inVal;
                    }
                    else if (validType == "truefalse") {
                        newRowCell.ValStr = inVal;
                    }
                    else if (validType == "date") {
                        if (typeof inVal === "object" && inVal === null) {
                            newRowCell.ValStr = null;
                        } else {
                            let d = null;
                            if (typeof inVal === "date") {
                                d = newVal;
                            }
                            else {
                                d = new Date(inVal);
                            }
                            newRowCell.ValStr = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                        }
                    }
                    else if (typeof inVal === "string") {
                        if (inVal.length > 0 && inVal[0] === '=') {
                            newRowCell.Formula = inVal;
                        }
                        else {
                            newRowCell.ValStr = inVal;
                        }
                    }
                    else if (validType == "text") {
                        newRowCell.ValStr = inVal;
                    }
                    else if (typeof inVal === "number") {
                        newRowCell.Val = inVal;
                    }
                    else if (typeof inVal === "object" && inVal == null) {
                        newRowCell.Val = null;
                    }
                    else if (typeof inVal === "undefined") {
                        newRowCell.Val = null;
                    }
                    else {
                        console.warn(`Invalid js type: ${typeof inVal}`);
                    }

                    if (sqlPGSimplyfiedProps_OVERRIDE) {

                        let sqlPGSimplyfiedProps_OVERRIDE_arr = sqlPGSimplyfiedProps_OVERRIDE.split(',');

                        if (sqlPGSimplyfiedProps_OVERRIDE_arr.indexOf("readonly") != -1) {
                            newRowCell.Meta.Save.ReadOnly = true;
                        }
                    }



                    {
                        let firstRowFormatCell = pgridDataDyn[this.y][this.x + eCol];
                        if (firstRowFormatCell != null && "Meta" in firstRowFormatCell && "Style" in firstRowFormatCell.Meta) {
                            if (typeof newRowCell == "undefined") {
                                newRowCell = {};
                            }

                            if (newRowCell == null) {
                                newRowCell = {};
                            }
                            if (!("Meta" in newRowCell)) {
                                newRowCell.Meta = {};
                            }

                            newRowCell.Meta.Style = firstRowFormatCell.Meta.Style;
                        }
                    }


                    if (r == 0) {


                        let newRowCell_tmpl = JSON.parse(JSON.stringify(newRowCell));
                        delete newRowCell_tmpl.Val;
                        delete newRowCell_tmpl.ValStr;
                        // delete newRowCell_tmpl.Meta.Save.ReadOnly;

                        if ("NewRow" in specColDef) {
                            newRowCell_tmpl = lodash_merge(newRowCell_tmpl, specColDef.NewRow);
                        }

                        newRowTemplate.push(newRowCell_tmpl);
                    }

                    newRow.push(factRangeData[r][eCol] = newRowCell);
                }




            }

            lodash_set(factRangeData[0][0], "Meta.OT.NewRowTmpl", newRowTemplate);

        }
        //Only load if has Load meta in LRCell



        lodash_set(pgridDataLRCell, "Meta.yLRLength", this.yLength);
        lodash_set(pgridDataLRCell, "Meta.xLRLength", this.xLength);



        /*
            "OverlayOT": {
                "Items": [
                    {
                        "Rows": "4,5",
                        "Columns": "6,7,8,9",
                        "CellTemplate": {
                            "Meta": {
                                "Save": {
                                    "ReadOnly": true,
                                    "Writable": false
                                },
                                "Style": "background-color: #E600E6"
                            }
                        }
                    }
                ]
            }
        */

        const DEFAULT_MERGE_STRATIGIES = ["KeepValOnEmptyValStr"];

        let overlayOTitems = lodash_get(this.linkedRange, "OverlayOT.Items", []);
        if (overlayOTitems) {
            for (let oIdx = 0; oIdx < overlayOTitems.length; oIdx++) {

                let itm = overlayOTitems[oIdx];

                let Description = null; //The overlay item description



                let Name = lodash_get(itm, "Name", null);
                let Rows = lodash_get(itm, "Rows", "").split(',').map(x => Number(x));
                let Columns = lodash_get(itm, "Columns", "").split(',').map(x => Number(x));
                Description = lodash_get(itm, "Description", null);
                let MergeStrategies = lodash_get(itm, "MergeStrategies", DEFAULT_MERGE_STRATIGIES);

                let CellTemplate = lodash_get(itm, "CellTemplate", null);

                let Disabled = lodash_get(itm, "Disabled", false);

                for (let oRowI = 0; oRowI < factRangeData.length; oRowI++) {
                    if (Rows.indexOf(oRowI) != -1) {
                        for (let oColI = 0; oColI < factRangeData[0].length; oColI++) {
                            if (Columns.indexOf(oColI) != -1) {

                                if (CellTemplate) {
                                    let merged_cell = PGridCell.mergeCells(factRangeData[oRowI][oColI], CellTemplate, MergeStrategies);

                                    factRangeData[oRowI][oColI] = merged_cell;
                                }
                            }
                        }
                    }
                }
            }

        }

        return factRangeData;
    }

    Phase7_Insert_FactRange(pgridDataDyn, hotTable, factRangeData) {

        let myLR = PGridMatrices.Get_LRsFromTable(pgridDataDyn, { onlyThisLR: this.name, source: `pgridLR_OpenTable.js Phase7_Insert_FactRange() name: ${this.name}` });
        this.x = myLR.x;
        this.y = myLR.y;

        this.lrIsHidden = myLR.lrIsHidden;
        this.NoRowsInsert = myLR.NoRowsInsert;

        let ret = { pgridDataInserted: null, hotTableInserted: null, insertedRows: 0 };


        if (this.OutputMode == 'mutatestore') {


            for (let y = 0; y < factRangeData.length /*pgridDataLRCell.Meta.Load.DimSizeY*/; y++) {

                if (y == 0) {
                    if (factRangeData[0].length != 3) {
                        throw Error(`mutatestore expect exactly three columns. ([UpdateType], [UpdateKey], [UpdateValue])`)
                    }
                }

                let updateType = null;
                let updateKey = null;
                let updateValObj = null;
                let updateValue = null;

                updateType = factRangeData[y][0].ValStr;
                updateKey = factRangeData[y][1].ValStr;
                updateValObj = factRangeData[y][2]
                updateValue = 'Val' in updateValObj ? updateValObj.Val : updateValObj.ValStr

                if (updateType == 'FiltersUpdate') {
                    PGridVueStore.commit('Mutation_FiltersUpdate', { prop: updateKey, val: updateValue, source: `pgridLR_OpenTable.js Phase7_Insert_FactRange() mutatestore` });
                } else if (updateType == 'PGridSettings') {
                    PGridVueStore.commit('Mutation_UpdatePGridSettings', { prop: updateKey, val: updateValue, source: `pgridLR_OpenTable.js Phase7_Insert_FactRange() mutatestore` });
                }
            }




        }

        //This avoid re-insertion of rows when going to show hidden mode
        if (PGridVueStore.state.pgridSettings.PGridTableShowHiddenMode && !this.lrIsHidden) {
            return ret;
        }

        let hotTableInserted = null;
        let pgridDataInserted = null;
        try {

            pgridDataInserted = pgridDataDyn; //FUB is clone nessecary?
            hotTableInserted = hotTable;

            for (let y = 0; y < factRangeData.length /*pgridDataLRCell.Meta.Load.DimSizeY*/; y++) {

                const insertY = this.y + y;


                if (!this.NoRowsInsert) {

                    if (y != 0) { //Don`t add row for the first data row
                        // var foo00 = lodash_clone(pgridDataInserted); console.debug("AddEmptyRow pgridDataInserted before: " + this.name, lodash_clone(foo00))

                        PGridMatrices.AddEmptyRow_OTPGrid(pgridDataInserted, 1, insertY);
                        PGridMatrices.AddEmptyRow_HT(hotTableInserted, 1, insertY);
                        // var foo01 = lodash_clone(pgridDataInserted); console.debug("AddEmptyRow pgridDataInserted  after: " + this.name, lodash_clone(foo01))
                    }


                    ret.insertedRows = ret.insertedRows + 1;


                } else {

                    if (y == 0) {
                        let gridRowsBefore = pgridDataInserted.length;
                        if (gridRowsBefore < this.y + factRangeData.length) {
                            PGridMatrices.EnsureDimension(pgridDataInserted, this.y + factRangeData.length, this.x + factRangeData[0].length);
                            PGridMatrices.EnsureDimension(hotTableInserted, this.y + factRangeData.length, this.x + factRangeData[0].length);
                            let gridRowsAfter = pgridDataInserted.length;
                            ret.insertedRows = gridRowsAfter - gridRowsBefore;
                        }

                    }
                    // if (pgridDataInserted.length <= insertY) { //We must expand
                    //     PGridMatrices.AddEmptyRow_OTPGrid(pgridDataInserted, factRangeData.length, insertY-1);
                    //     PGridMatrices.AddEmptyRow_HT(hotTableInserted, factRangeData.length, insertY-1);
                    // }
                }

                // this.yLength = this.yLength + 1;

                for (let x = 0; x < factRangeData[0].length /* pgridDataLRCell.Meta.Load.DimSizeX */; x++) {



                    const insertX = this.x + x;

                    let insertFact = factRangeData[y][x];

                    if (insertFact != null) {


                        if (pgridDataInserted[0].length < insertX + 1) {
                            PGridMatrices.AddEmptyCol(pgridDataInserted, 1);
                            PGridMatrices.AddEmptyCol(hotTableInserted, 1);
                        }

                        let currCell = pgridDataInserted[insertY][insertX];

                        let mergedCell = mergeCells(currCell, insertFact, ["KeepValStr", "KeepVal"]);

                        if (this.lrIsHidden) {
                            mergedCell.Format = "pcell-hidden";
                            // if("Meta" in merged_cell){
                            //     delete merged_cell.Meta.Style
                            // }
                        }

                        pgridDataInserted[insertY][insertX] = mergedCell;

                        let hotValue = getHotValue(mergedCell);
                        hotTableInserted[insertY][insertX] = hotValue;

                    }
                }
            }

        } catch (err) {
            let errMsg = `> Phase7_Insert_FactRange_error got exception: ${err.message || err}`;
            throw new Error(errMsg);
        }

        {
            /* Add CSS here insted of in Phase7_Insert_FactRange */
            this.y = PGridMatrices.Get_LRsFromTable(pgridDataInserted, { onlyThisLR: this.name, source: `pgridLR_OpenTable.js Phase7_Insert_FactRange()` }).y;

            let yLR = this.y;
            let xLR = this.x;

            // PGridMatrices.ApplyCssStyles(["pg-is-colheader"], pgridDataInserted, yLR - this.yLengthHeaders, xLR, this.yLengthHeaders, this.xLength);


            lodash_set(pgridDataInserted[yLR][xLR], "Meta.yLRLength", this.yLength);
            lodash_set(pgridDataInserted[yLR][xLR], "Meta.xLRLength", this.xLength);

            if (!this.lrIsHidden) {

                PGridMatrices.ApplyCssStyles(["pg-is-lr-just-above"], pgridDataInserted, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);

                PGridMatrices.ApplyCssStyles(["pg-is-colheader"], pgridDataInserted, yLR - this.yLengthHeaders, xLR, this.yLengthHeaders, this.xLength);

                if (!this.SkippHeaderStyling && !(this.NoHeader)) {
                    PGridMatrices.ApplyCssStyles(["pg-is-rowheader"], pgridDataInserted, yLR, xLR - this.xLengthHeaders, this.yLength, this.xLengthHeaders);
                }
                PGridMatrices.ApplyCssStyles(["pg-is-lr", "pg-is-lr2", "pg-is-OpenTable", "pg-is-OpenTable2"], pgridDataInserted, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, this.yLength + this.yLengthHeaders, this.xLength + this.xLengthHeaders); /*pg-is-lr gets remove somehow, henche also pg-is-lr2*/
                PGridMatrices.ApplyCssStyles(["pg-is-lr-border-top"], pgridDataInserted, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);
                PGridMatrices.ApplyCssStyles(["pg-is-lr-border-left"], pgridDataInserted, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders, this.yLength + this.yLengthHeaders, 1);
                PGridMatrices.ApplyCssStyles(["pg-is-lr-border-bottom"], pgridDataInserted, yLR + this.yLength, xLR - this.xLengthHeaders, 1, this.xLength + this.xLengthHeaders);
                PGridMatrices.ApplyCssStyles(["pg-is-lr-border-right"], pgridDataInserted, yLR - this.yLengthHeaders, xLR + this.xLength, this.yLength + this.yLengthHeaders, 1);
                PGridMatrices.ApplyCssStyles(["pg-is-lr-just-left"], pgridDataInserted, yLR - this.yLengthHeaders, xLR - this.xLengthHeaders - 1, this.yLength + this.yLengthHeaders, 1);
                PGridMatrices.ApplyCssStyles(["pg-is-content",], pgridDataInserted, yLR, xLR, this.yLength, this.xLength);
                PGridMatrices.ApplyCssStyles(["pg-is-colheader"], pgridDataInserted, yLR - this.yLengthHeaders, xLR, this.yLengthHeaders, this.xLength);

                for (let lvl = 0; lvl < this.xLengthHeaders; lvl++) {
                    PGridMatrices.ApplyCssStyles([`pg-is-rowheader-lvl-${lvl}`], pgridDataInserted, yLR, xLR - this.xLengthHeaders + lvl, this.yLength, 1);
                }
                for (let lvl = 0; lvl < this.yLengthHeaders; lvl++) {
                    PGridMatrices.ApplyCssStyles([`pg-is-colheader-lvl-${lvl}`], pgridDataInserted, yLR - this.yLengthHeaders + lvl, xLR, 1, this.xLength);
                }

                //The upper left "cackebite"
                if (this.RemoveUpperRightCakebite) {
                    PGridMatrices.ApplyCssStyles([
                        "pg-is-lr-just-above",
                        "pg-is-lr-just-left",
                        "pg-is-lr",
                        "pg-is-lr2",
                        "pg-is-OpenTable",
                        "pg-is-lr-border-top",
                        "pg-is-lr-border-left"
                    ], pgridDataInserted, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders - 1, this.yLengthHeaders + 1, this.xLengthHeaders + 1, false);
                }

                PGridMatrices.ApplyCssStyles([
                    "pg-is-lr-just-above"
                ], pgridDataInserted, yLR - 1, xLR - this.xLengthHeaders, 1, this.xLengthHeaders, true);


                //Place the right cell border on firt non-hidden col left of LR
                {
                    function firstNonHiddenCol(pgHiddenColumns, startCol = 0) {
                        let ret = 0;
                        let i = -1;

                        for (i = startCol; i >= 0 && ret === null; i--) {

                            if (pgHiddenColumns[i] === false) {
                                ret = i
                            }
                        }

                        return ret;
                    }


                    let lrOccupiedCols = [];
                    for (let hx = 0; hx < xLR + this.xLength; hx++) {

                        if (hx >= xLR && hx < xLR + this.xLength) {
                            lrOccupiedCols.push(true);
                        } else {
                            lrOccupiedCols.push(false);
                        }
                    }

                    let lrNonHiddenCols = [];
                    for (let hx = 0; hx < xLR + this.xLength; hx++) {

                        if (!(lodash_get(PGridVueStore.state.hotSettings.pgHiddenColumns, `[${hx}]`, false))) {
                            lrNonHiddenCols.push(true);
                        } else {
                            lrNonHiddenCols.push(false);
                        }
                    }


                    let foundNonHiddenColInLr = false;
                    let revcountNonHiddenColsUntilFirstShownLRCol = 0;
                    let countColsUntilFirstShownLRCol = 0;


                    //1. Find first non hidden col in LR
                    for (let hx = 0; hx < xLR + this.xLength && !foundNonHiddenColInLr; hx++) {

                        if (lrOccupiedCols[hx] && lrNonHiddenCols[hx] && hx >= xLR) {
                            foundNonHiddenColInLr = true;
                        } else {
                            if (lrNonHiddenCols[hx]) {
                                revcountNonHiddenColsUntilFirstShownLRCol++;
                            }
                            countColsUntilFirstShownLRCol++;
                        }
                    }



                    //2. Find first non hidden col to left of LR (for left border)
                    revcountNonHiddenColsUntilFirstShownLRCol = countColsUntilFirstShownLRCol - 1;
                    foundNonHiddenColInLr = false;
                    for (let hx = countColsUntilFirstShownLRCol - 1; hx > 0 && !foundNonHiddenColInLr; hx--) {

                        if (!lrOccupiedCols[hx] && lrNonHiddenCols[hx]) {
                            foundNonHiddenColInLr = true;
                        } else {
                            revcountNonHiddenColsUntilFirstShownLRCol--;
                        }
                    }


                    // console.log('LR:' + this.name)
                    // console.log('lrOccupiedCols', lrOccupiedCols)
                    // console.log('lrNonHiddenCols', lrNonHiddenCols)

                    // console.log('foundNonHiddenColInLr', foundNonHiddenColInLr)
                    // console.log('countNonHiddenColsUntilFirstShownLRCol', revcountNonHiddenColsUntilFirstShownLRCol)
                    // console.log('countColsUntilFirstShownLRCol', countColsUntilFirstShownLRCol)
                    // console.log('revcountNonHiddenColsUntilFirstShownLRCol', revcountNonHiddenColsUntilFirstShownLRCol)


                    // let colNextToLR = countNonHiddenColsUntilFirstShownLRCol - 1; //xLR - 1 - nonHiddenColumnsTotal;//firstNonHiddenCol(hiddenColumnsTotal, xLR - 1);
                    let colNextToLR = revcountNonHiddenColsUntilFirstShownLRCol; // countColsUntilFirstShownLRCol - 1;

                    if (colNextToLR >= 0) {


                        PGridMatrices.ApplyCssStyles([
                            "pg-is-lr-just-left"
                        ], pgridDataInserted, yLR - this.yLengthHeaders, colNextToLR, this.yLengthHeaders + this.yLength, 1, true);

                    }

                }


                if (this.overlayCSSClass) {
                    PGridMatrices.ApplyCssStyles([
                        this.overlayCSSClass
                    ], pgridDataInserted, yLR - this.yLengthHeaders - 1, xLR - this.xLengthHeaders - 1, this.yLength + this.yLengthHeaders + 2, this.xLength + this.xLengthHeaders + 2, true);
                }


            }

        }



        ret.pgridDataInserted = pgridDataInserted;
        ret.hotTableInserted = hotTableInserted;

        return ret;
    }


}



