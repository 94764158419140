<template>
  <div v-bind:class="['pgrid-guidances-list']">
    <div v-bind:class="['pgrid-guidance noprint']"
      v-bind:style="{ display: 'table', color: 'blue', minWidth: guidance.Guidance_MinWidth }">
      <span class="pgrid-guidance-frame" v-bind:style="{display: 'table-cell', color: guidance.Guidance_Color, borderColor: guidance.Guidance_Color}">
        <p class="pgrid-guidance-inner pgrid-overflow-ellipsis" v-html="guidance.Guidance_Html"
          v-bind:title="guidance.Guidance_Html" />
      </span>
    </div>
  </div>
</template>
<script>
import Vue from "vue/dist/vue.js";

export default {
  name: "pgrid-guidance",

  
  props: {
    guidanceIdx: { type: Number, required: true },
  },

  mounted: async function () {
    /******************************************* */
    //Check this out: https://jsfiddle.net/8wg7o6c6/
    /******************************************* */

    let context = this.$store;

    // await PGridHandlers.Handle_FilterMounted(context);
  },
  methods: {},
  computed: {
    guidance() {
      return this.$store.state.pgridFilter.Guidances[this.guidanceIdx];
    }  },
  components: {},
};
</script>
<style>

.pgrid-guidances-list {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  float: left;

  /* flex-wrap: nowrap; */
  flex-wrap: wrap;

  -webkit-align-items: flex-start;
  align-items: flex-start;
}



.pgrid-guidance {
  padding: 0;
  margin: 0;
  margin-right: 0px;
  margin-left: 5px;
  margin-bottom: 4px;
  /* display: inline-block; */
  overflow: hidden;
  min-width: 100px;
  height: 54.95px;
  max-height: 54.95px;
  /* height: 84px; */

}


.pgrid-guidance-frame {
  margin: 4px;
  margin-bottom: -19px;
  border: solid rgb(250, 84, 84) 1px;
  color: rgb(255, 41, 41);
  border-radius: 8px;
  vertical-align: middle;
}

.pgrid-guidance-frame a {
  color: inherit;
}

/*
.guidancehtmlmode-exclusiverow .pgrid-guidance-frame {
  vertical-align: right;
}
*/

.pgrid-guidance.guidancehtmlmode-exclusiverow {
  /* vertical-align: right; */
  float: right;
}

.pgrid-guidance-inner {
  margin: 0 auto;
  max-width: calc(100% - 25px);
  line-height: 1.3;
  vertical-align: middle;
  text-align: center;
}

p.pgrid-overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
